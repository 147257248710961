export interface Organization {
  id: number;
  name: string;
  description: string;
  showBuildingProjectId: boolean;
  showCustomerId: boolean;
  useCustomerData: boolean;
  useSupplierData: boolean;
  isBuildingProjectIdRequired: boolean;
  isCustomerIdRequired: boolean;
  availableExportType: availableExportTypeOptions;
  showManufacturerTypes: boolean;
}

export interface OrganizationKeywords {
  id: number;
  positive: string[];
  negative: string[];
  own: string[];
  external: string[];
}

export interface keyable {
  [key: string]: any;
}

export interface OrganizationExport {
  exportFunction: exportFunctionType;
  renderFunction: renderFunctionType;
  configuration: keyable;
  isEnabled: boolean;
}

export type exportFunctionType = "sftp_export" | "rest_export";
export type renderFunctionType = "xml_idoc_orders05" | "json_renderer";

export const EXPORT_FUNCTION_TYPES: exportFunctionType[] = [
  "rest_export",
  "sftp_export",
];
export const RENDER_FUNCTION_TYPES: renderFunctionType[] = [
  "json_renderer",
  "xml_idoc_orders05",
];

export const EXPORT_FUNCTION_TYPES_LABELS = {
  sftp_export: "SFTP",
  rest_export: "REST",
};

export type availableExportTypeOptions = "ASYNC" | "SYNC" | null;

export const RENDER_FUNCTION_TYPES_LABELS = {
  xml_idoc_orders05: "IDoc XML",
  json_renderer: "JSON",
};

export enum ImportFunctionType {
  SFTP_IDOC = "sftp_idoc",
}

export const ImportFunctionOptions = [
  { label: "IDoc via SFTP", value: ImportFunctionType.SFTP_IDOC },
];

export interface DataImport {
  importFunction: ImportFunctionType;
  isEnabled: boolean;
  configuration: keyable;
}

export interface MonthlyUsage {
  month: number;
  year: number;
  amount: number;
}
