<template>
  <q-btn
    icon="sym_r_download"
    color="neutral-7"
    flat
    dense
    :disable="isDisabled"
  >
    <q-menu>
      <q-list dense>
        <q-item
          clickable
          v-ripple
          v-close-popup
          @click="downloadExcelOfferFormat"
        >
          <q-item-section>
            {{ $t("downloadInquiryButton.excelOfferFormat") }}
          </q-item-section>
        </q-item>
        <q-item
          clickable
          v-ripple
          v-close-popup
          @click="downloadExcelBOQFormat"
        >
          <q-item-section>
            {{ $t("downloadInquiryButton.excelBOQFormat") }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <q-tooltip> {{ $t("downloadInquiryButton.tooltip") }}</q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import * as api from "@/api/inquiry";
import { useRouteParams } from "@/composables/useRouteParams";
import { computed } from "vue";

const { organizationId, inboxId, inquiryId } = useRouteParams();

const isDisabled = computed(() =>
  [organizationId.value, inboxId.value, inquiryId.value].some((id) => isNaN(id))
);

async function downloadExcelOfferFormat() {
  if (isDisabled.value) return;
  await api.downloadInquiryAsExcelOfferFormat(
    organizationId.value,
    inboxId.value,
    inquiryId.value
  );
}

async function downloadExcelBOQFormat() {
  if (isDisabled.value) return;
  await api.downloadInquiryAsExcelBOQFormat(
    organizationId.value,
    inboxId.value,
    inquiryId.value
  );
}
</script>
