/** This store hold information about the current app user */
import type { FrontendPreferences } from "@/types/frontendPreferences";
import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";
import { useCurrentUserStore } from "./currentUser";

export const useFrontendPreferencesStore = defineStore(
  "frontendPreferences",
  () => {
    const userStore = useCurrentUserStore();
    const { user } = storeToRefs(userStore);
    const { updateUser } = userStore;

    const frontendPreferences = computed(() => user.value?.frontendPreferences);

    async function updateFrontendPreferences(
      preferences: Partial<FrontendPreferences>
    ) {
      if (!user.value) {
        return;
      }

      await updateUser({
        frontendPreferences: {
          ...user.value.frontendPreferences,
          ...preferences,
        },
      });
    }

    return {
      frontendPreferences,
      updateFrontendPreferences,
    };
  }
);
