import { client } from "@/api/client/client";
import type { Email, EmailAddress } from "@/types/email";
import type { Inquiry, ShortInquiry } from "@/types/inquiry";
import type { Paginated } from "@/types/pagination";
import { format, formatISO, parse, parseISO } from "date-fns";
import { parseCursor } from "./utils";

export interface InquiryQuery {
  text: string | null;
  filename: string | null;
  status: string | null;
  tags: string | null;
  assignedUserId: string | null;
  ordering: string | null;
  pageSize: number | null;
  pageResultCount: string;
  sender: string | null;
  buildingProject: string | null;
}

type ApiEmail = {
  sentAt: string | null;
  sender: string;
  recipients: string[];
  ccs: string[];
  bccs: string[];
} & Omit<Email, "sentAt" | "sender" | "recipients" | "ccs" | "bccs">;

type ApiShortInquiry = {
  createdAt: string;
  exportedAt: string | null;
  requestedSubmissionDate: string | null;
  requestedCustomerSubmissionDate: string | null;
  email: ApiEmail | null;
} & Omit<
  ShortInquiry,
  | "createdAt"
  | "exportedAt"
  | "requestedSubmissionDate"
  | "requestedCustomerSubmissionDate"
  | "email"
>;

type ApiInquiry = {
  createdAt: string;
  exportedAt: string | null;
  requestedSubmissionDate: string | null;
  requestedCustomerSubmissionDate: string | null;
  email: ApiEmail | null;
} & Omit<
  Inquiry,
  | "createdAt"
  | "exportedAt"
  | "requestedSubmissionDate"
  | "requestedCustomerSubmissionDate"
  | "email"
>;

export async function listInquiries(
  organizationId: number,
  inboxId: number,
  query: InquiryQuery,
  currentCursor: string | null
): Promise<Paginated<ShortInquiry>> {
  const params = { ...query, cursor: currentCursor };

  const response = await client.get<Paginated<ApiShortInquiry>>(
    `/inboxes/${inboxId}/inquiries/`,
    {
      params,
    }
  );

  const { data } = response;
  return {
    next: parseCursor(data.next),
    previous: parseCursor(data.previous),
    count: data.count,
    results: data.results.map(postprocessInquiry),
  };
}

export async function getInquiry(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  query?: InquiryQuery
): Promise<Inquiry> {
  const params = query || {};
  const response = await client.get(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/`,
    { params }
  );
  return postprocessInquiry(response.data) as Inquiry;
}

export async function deleteInquiry(
  inboxId: number,
  inquiryId: number
): Promise<void> {
  await client.delete(`/inboxes/${inboxId}/inquiries/${inquiryId}/`);
}

export async function moveInquiryToAnotherInbox(
  inboxId: number,
  inquiryId: number,
  targetInboxId: number
): Promise<Inquiry> {
  return await client.post(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/move_to_another_inbox/`,
    {
      targetInboxId,
    }
  );
}

export async function moveMultipleInquiriesToAnotherInbox(
  inboxId: number,
  targetInboxId: number,
  inquiryIds: number[]
): Promise<void> {
  await client.post(
    `/inboxes/${inboxId}/inquiries/bulk_move_to_another_inbox/`,
    {
      targetInboxId,
      ids: inquiryIds,
    }
  );
}

export async function updateInquiry(
  inboxId: number,
  inquiryId: number,
  data: any
): Promise<Inquiry> {
  const processedData = preprocessInquiry(data);
  const response = await client.patch(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/`,
    processedData
  );
  return postprocessInquiry(response.data);
}

export async function downloadInquiryFile(
  inboxId: number,
  inquiryId: number
): Promise<void> {
  window.open(
    `/api/inboxes/${inboxId}/inquiries/${inquiryId}/download_file/`,
    "_blank"
  );
}

export async function downloadInquiryEmail(
  inboxId: number,
  inquiryId: number
): Promise<void> {
  window.open(
    `/api/inboxes/${inboxId}/inquiries/${inquiryId}/download_email/`,
    "_blank"
  );
}

export async function downloadInquiryValues(
  inboxId: number,
  inquiryId: number
): Promise<void> {
  const url = `/api/inboxes/${inboxId}/inquiries/${inquiryId}/download_values/`;
  const link = document.createElement("a");
  link.download = "kinisto-values.json";
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function deleteMultipleInquiries(
  inboxId: number,
  inquiryIds: number[]
): Promise<void> {
  await client.delete(`/inboxes/${inboxId}/inquiries/delete_multiple/`, {
    params: { ids: inquiryIds.join(",") },
  });
}

export async function downloadMultipleInquiriesFiles(
  inboxId: number,
  inquiryIds: number[]
): Promise<void> {
  window.open(
    `/api/inboxes/${inboxId}/inquiries/download_multiple/?ids=${inquiryIds.join(
      ","
    )}`,
    "_blank"
  );
}

export async function downloadMultipleInquiriesValues(
  inboxId: number,
  inquiryIds: number[]
): Promise<void> {
  window.open(
    `/api/inboxes/${inboxId}/inquiries/download_values/?ids=${inquiryIds.join(
      ","
    )}`,
    "_blank"
  );
}

export async function updateMultipleInquiries(
  inboxId: number,
  leadIds: number[] | null,
  update: Partial<{ assignedUserId: number | null; status: string }>
): Promise<void> {
  await client.patch(
    `/inboxes/${inboxId}/inquiries/update_field_for_multiple_inquiries/` +
      getIdsQueryFromIdsList(leadIds),
    update
  );
}

export async function retryAnalysis(
  organizationId: number,
  inboxId: number,
  inquiryId: number
): Promise<void> {
  await client.post(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/retry_analysis/`
  );
}

export async function finalizeInquiry(
  inboxId: number,
  inquiryId: number,
  config?: any
): Promise<void> {
  await client.post(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/finalize/`,
    undefined,
    config
  );
}

export async function copyInquiry(
  inboxId: number,
  inquiryId: number
): Promise<Inquiry> {
  const response = await client.post(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/copy/`
  );
  return postprocessInquiry(response.data);
}

export async function exportInquiry(
  inboxId: number,
  inquiryId: number,
  config?: any
): Promise<void> {
  await client.post(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/export/`,
    undefined,
    config
  );
}

export async function setInquiryBOQs(
  inboxId: number,
  inquiryId: number,
  boqDocumentsIds: number[]
): Promise<void> {
  await client.post(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/set_boq_documents/`,
    {
      boqDocumentsIds,
    }
  );
}

export async function downloadInquiryAsExcelOfferFormat(
  organizationId: number,
  inboxId: number,
  inquiryId: number
): Promise<void> {
  window.open(
    `/api/inboxes/${inboxId}/inquiries/${inquiryId}/download_excel_offer_format/`,
    "_blank"
  );
}

export async function downloadInquiryAsExcelBOQFormat(
  organizationId: number,
  inboxId: number,
  inquiryId: number
): Promise<void> {
  window.open(
    `/api/inboxes/${inboxId}/inquiries/${inquiryId}/download_excel_boq_format/`,
    "_blank"
  );
}

export async function addTag(
  inboxId: number,
  inquiryId: number,
  tagId: number
): Promise<void> {
  await client.post(`/inboxes/${inboxId}/inquiries/${inquiryId}/add_tag/`, {
    id: tagId,
  });
}

export async function removeTag(
  inboxId: number,
  inquiryId: number,
  tagId: number
): Promise<void> {
  await client.post(`/inboxes/${inboxId}/inquiries/${inquiryId}/remove_tag/`, {
    id: tagId,
  });
}

export async function subscribeToNotifications(
  inboxId: number,
  inquiryId: number
) {
  await client.post(`/inboxes/${inboxId}/inquiries/${inquiryId}/subscribe/`);
}

export async function unsubscribeFromNotifications(
  inboxId: number,
  inquiryId: number
) {
  await client.post(`/inboxes/${inboxId}/inquiries/${inquiryId}/unsubscribe/`);
}

export function getIdsQueryFromIdsList(inquiryIds: number[] | null): string {
  return inquiryIds ? `?ids=${inquiryIds.join(",")}` : "?ids=all";
}

function preprocessInquiry(object: Partial<Inquiry>): Partial<ApiInquiry>;
function preprocessInquiry(
  object: Partial<ShortInquiry>
): Partial<ApiShortInquiry>;
function preprocessInquiry(
  object: Partial<Inquiry> | Partial<ShortInquiry>
): Omit<Partial<ApiInquiry> | Partial<ApiShortInquiry>, "email"> & {
  email?: Partial<ApiEmail> | null;
} {
  const {
    createdAt,
    exportedAt,
    requestedSubmissionDate,
    requestedCustomerSubmissionDate,
    email,
    ...rest
  } = object;

  const newRequestedSubmissionDate = requestedSubmissionDate
    ? format(requestedSubmissionDate, "yyyy-MM-dd")
    : requestedSubmissionDate;
  const newRequestedCustomerSubmissionDate = requestedCustomerSubmissionDate
    ? format(requestedCustomerSubmissionDate, "yyyy-MM-dd")
    : requestedCustomerSubmissionDate;
  const newCreatedAt = createdAt ? formatISO(createdAt) : createdAt;
  const newExportedAt = exportedAt ? formatISO(exportedAt) : exportedAt;
  const newEmail = email ? preprocessEmail(email) : email;
  return {
    ...rest,
    email: newEmail,
    createdAt: newCreatedAt,
    exportedAt: newExportedAt,
    requestedSubmissionDate: newRequestedSubmissionDate,
    requestedCustomerSubmissionDate: newRequestedCustomerSubmissionDate,
  };
}

function preprocessEmail(email: Partial<Email>): Partial<ApiEmail> {
  const { sentAt, sender, recipients, ccs, bccs, ...rest } = email;
  return {
    ...rest,
    sentAt: sentAt ? formatISO(sentAt) : sentAt,
    sender: sender?.displayAddress,
    recipients: recipients?.map((r) => r.displayAddress),
    ccs: ccs?.map((r) => r.displayAddress),
    bccs: bccs?.map((r) => r.displayAddress),
  };
}

function postprocessInquiry(object: ApiInquiry): Inquiry;
function postprocessInquiry(object: ApiShortInquiry): ShortInquiry;
function postprocessInquiry(
  object: ApiInquiry | ApiShortInquiry
): Inquiry | ShortInquiry {
  const {
    createdAt,
    exportedAt,
    requestedSubmissionDate,
    requestedCustomerSubmissionDate,
    email,
    ...rest
  } = object;

  const newRequestedSubmissionDate = requestedSubmissionDate
    ? parse(requestedSubmissionDate, "yyyy-MM-dd", new Date())
    : null;
  const newRequestedCustomerSubmissionDate = requestedCustomerSubmissionDate
    ? parse(requestedCustomerSubmissionDate, "yyyy-MM-dd", new Date())
    : null;
  return {
    ...rest,
    email: postprocessEmail(email),
    createdAt: parseISO(createdAt),
    exportedAt: exportedAt ? parseISO(exportedAt) : null,
    requestedSubmissionDate: newRequestedSubmissionDate,
    requestedCustomerSubmissionDate: newRequestedCustomerSubmissionDate,
  };
}

function postprocessEmail(email: ApiEmail | null): Email | null {
  if (!email) return null;
  const { sentAt, sender, recipients, ccs, bccs, ...rest } = email;
  return {
    ...rest,
    sentAt: sentAt ? parseISO(sentAt) : null,
    sender: parseEmailAddress(sender),
    recipients: recipients.map(parseEmailAddress),
    ccs: ccs.map(parseEmailAddress),
    bccs: bccs.map(parseEmailAddress),
  };
}

function parseEmailAddress(full: string): EmailAddress {
  const match = full.match(/(.*) <(.*)>/);
  if (!match) {
    return { email: full, displayAddress: full, shortDisplayAddress: full };
  }
  const [, fullName, email] = match;
  const [firstName, lastName] = fullName.split(" ");
  const shortDisplayAddress = fullName ? fullName : email;
  return {
    email,
    displayAddress: full,
    shortDisplayAddress,
    fullName,
    firstName,
    lastName,
  };
}
